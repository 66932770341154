exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-classes-tsx": () => import("./../../../src/pages/classes.tsx" /* webpackChunkName: "component---src-pages-classes-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-krav-tsx": () => import("./../../../src/pages/krav.tsx" /* webpackChunkName: "component---src-pages-krav-tsx" */),
  "component---src-pages-pilot-tsx": () => import("./../../../src/pages/pilot.tsx" /* webpackChunkName: "component---src-pages-pilot-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-tech-tsx": () => import("./../../../src/pages/tech.tsx" /* webpackChunkName: "component---src-pages-tech-tsx" */),
  "component---src-templates-tech-post-tsx-content-file-path-opt-build-repo-content-krav-combos-mdx": () => import("./../../../src/templates/tech-post.tsx?__contentFilePath=/opt/build/repo/content/krav/combos.mdx" /* webpackChunkName: "component---src-templates-tech-post-tsx-content-file-path-opt-build-repo-content-krav-combos-mdx" */),
  "component---src-templates-tech-post-tsx-content-file-path-opt-build-repo-content-pilot-pre-solo-mdx": () => import("./../../../src/templates/tech-post.tsx?__contentFilePath=/opt/build/repo/content/pilot/pre-solo.mdx" /* webpackChunkName: "component---src-templates-tech-post-tsx-content-file-path-opt-build-repo-content-pilot-pre-solo-mdx" */),
  "component---src-templates-tech-post-tsx-content-file-path-opt-build-repo-content-projects-muslce-sensor-mdx": () => import("./../../../src/templates/tech-post.tsx?__contentFilePath=/opt/build/repo/content/projects/muslce-sensor.mdx" /* webpackChunkName: "component---src-templates-tech-post-tsx-content-file-path-opt-build-repo-content-projects-muslce-sensor-mdx" */),
  "component---src-templates-tech-post-tsx-content-file-path-opt-build-repo-content-tech-clustering-algorithms-mdx": () => import("./../../../src/templates/tech-post.tsx?__contentFilePath=/opt/build/repo/content/tech/clustering-algorithms.mdx" /* webpackChunkName: "component---src-templates-tech-post-tsx-content-file-path-opt-build-repo-content-tech-clustering-algorithms-mdx" */),
  "component---src-templates-tech-post-tsx-content-file-path-opt-build-repo-content-tech-convolutional-neural-networks-mdx": () => import("./../../../src/templates/tech-post.tsx?__contentFilePath=/opt/build/repo/content/tech/convolutional-neural-networks.mdx" /* webpackChunkName: "component---src-templates-tech-post-tsx-content-file-path-opt-build-repo-content-tech-convolutional-neural-networks-mdx" */),
  "component---src-templates-tech-post-tsx-content-file-path-opt-build-repo-content-tech-debugging-react-app-mdx": () => import("./../../../src/templates/tech-post.tsx?__contentFilePath=/opt/build/repo/content/tech/debugging-react-app.mdx" /* webpackChunkName: "component---src-templates-tech-post-tsx-content-file-path-opt-build-repo-content-tech-debugging-react-app-mdx" */),
  "component---src-templates-tech-post-tsx-content-file-path-opt-build-repo-content-tech-google-bert-tutorial-mdx": () => import("./../../../src/templates/tech-post.tsx?__contentFilePath=/opt/build/repo/content/tech/google-bert-tutorial.mdx" /* webpackChunkName: "component---src-templates-tech-post-tsx-content-file-path-opt-build-repo-content-tech-google-bert-tutorial-mdx" */),
  "component---src-templates-tech-post-tsx-content-file-path-opt-build-repo-content-tech-injection-attacks-with-javascript-mdx": () => import("./../../../src/templates/tech-post.tsx?__contentFilePath=/opt/build/repo/content/tech/injection-attacks-with-javascript.mdx" /* webpackChunkName: "component---src-templates-tech-post-tsx-content-file-path-opt-build-repo-content-tech-injection-attacks-with-javascript-mdx" */),
  "component---src-templates-tech-post-tsx-content-file-path-opt-build-repo-content-tech-javascript-developer-lessons-mdx": () => import("./../../../src/templates/tech-post.tsx?__contentFilePath=/opt/build/repo/content/tech/javascript-developer-lessons.mdx" /* webpackChunkName: "component---src-templates-tech-post-tsx-content-file-path-opt-build-repo-content-tech-javascript-developer-lessons-mdx" */),
  "component---src-templates-tech-post-tsx-content-file-path-opt-build-repo-content-tech-machine-learning-basics-mdx": () => import("./../../../src/templates/tech-post.tsx?__contentFilePath=/opt/build/repo/content/tech/machine-learning-basics.mdx" /* webpackChunkName: "component---src-templates-tech-post-tsx-content-file-path-opt-build-repo-content-tech-machine-learning-basics-mdx" */),
  "component---src-templates-tech-post-tsx-content-file-path-opt-build-repo-content-tech-passwordless-authentication-mdx": () => import("./../../../src/templates/tech-post.tsx?__contentFilePath=/opt/build/repo/content/tech/passwordless-authentication.mdx" /* webpackChunkName: "component---src-templates-tech-post-tsx-content-file-path-opt-build-repo-content-tech-passwordless-authentication-mdx" */),
  "component---src-templates-tech-post-tsx-content-file-path-opt-build-repo-content-tech-proxy-server-mdx": () => import("./../../../src/templates/tech-post.tsx?__contentFilePath=/opt/build/repo/content/tech/proxy-server.mdx" /* webpackChunkName: "component---src-templates-tech-post-tsx-content-file-path-opt-build-repo-content-tech-proxy-server-mdx" */),
  "component---src-templates-tech-post-tsx-content-file-path-opt-build-repo-content-tech-svm-algorithm-mdx": () => import("./../../../src/templates/tech-post.tsx?__contentFilePath=/opt/build/repo/content/tech/svm-algorithm.mdx" /* webpackChunkName: "component---src-templates-tech-post-tsx-content-file-path-opt-build-repo-content-tech-svm-algorithm-mdx" */),
  "component---src-templates-tech-post-tsx-content-file-path-opt-build-repo-content-tech-voice-recognition-tensorflow-mdx": () => import("./../../../src/templates/tech-post.tsx?__contentFilePath=/opt/build/repo/content/tech/voice-recognition-tensorflow.mdx" /* webpackChunkName: "component---src-templates-tech-post-tsx-content-file-path-opt-build-repo-content-tech-voice-recognition-tensorflow-mdx" */)
}

